import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../styles/Game.css';
import DOMPurify from 'dompurify/dist/purify.min';
import articleTitles from './article_titles.json';

function Game() {
  const [startPage, setStartPage] = useState(null);
  const [endPage, setEndPage] = useState(null);
  const [currentPageContent, setCurrentPageContent] = useState('');
  const [clickCount, setClickCount] = useState(0);
  const [timer, setTimer] = useState(0);
  const [gameEnded, setGameEnded] = useState(false);
  const [currentPageTitle, setCurrentPageTitle] = useState('');
  const [clickedLinks, setClickedLinks] = useState([]);
  const [hoveredLink, setHoveredLink] = useState('');
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoveredDescription, setHoveredDescription] = useState('');
  const [showHoveredContent, setShowHoveredContent] = useState(false);
  const MAX_CONCURRENT_REQUESTS = 2;
  const requestQueue = [];
  let activeRequests = 0;

  const resetRequestState = () => {
    activeRequests = 0;
    requestQueue.length = 0;  // Clears the queue
  };

  useEffect(() => {
    let timerInterval;
    if (!gameEnded) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [gameEnded]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${minutes}:${formattedSeconds}`;
    }
  };

  useEffect(() => {
    initializeGame();
  }, []);

  const fetchRandomPage = () => {
    const randomIndex = Math.floor(Math.random() * articleTitles.length);
    return articleTitles[randomIndex];
  };

  const initializeGame = async () => {
    let validPairFound = false;

    while (!validPairFound) {
      const start = fetchRandomPage();
      let end = fetchRandomPage();

      while (start === end) {
        end = fetchRandomPage();
      }

      try {
        console.log('Checking for direct link from start to end');
        const response = await axios.get(
          `https://en.wikipedia.org/api/rest_v1/page/html/${encodeURIComponent(start)}`
        );
        const startPageContent = response.data;

        if (!startPageContent.includes(`/wiki/${encodeURIComponent(end)}`)) {
          validPairFound = true;
          setStartPage(start);
          setEndPage(end);
          fetchPageContent(start);
        } else {
          console.log('Direct link found, retrying with a new end page.');
        }
      } catch (error) {
        console.error('Error fetching page content for validation:', error);
      }
    }
  };

  const fetchPageContent = useCallback(
    (title, retryCount = 0) => {
      return new Promise((resolve, reject) => {
        requestQueue.push(() => fetchPageContentInternal(title, retryCount, resolve, reject));
        processQueue();
      });
    },
    []
  );

  const fetchPageContentInternal = async (title, retryCount, resolve, reject) => {
    if (activeRequests >= MAX_CONCURRENT_REQUESTS) {
      resolve();
      return;
    }

    activeRequests++;

    try {
      console.log('Fetching content for:', title);
      const encodedTitle = encodeURIComponent(title).replace(/[()]/g, (c) => `%${c.charCodeAt(0).toString(16)}`);
      const response = await axios.get(
        `https://en.wikipedia.org/api/rest_v1/page/html/${encodedTitle}`
      );
      console.log('Content fetched for:', title);
      resolve();
      setCurrentPageContent(response.data);
      setCurrentPageTitle(title);
      window.scrollTo(0, 0);
      fetchPageImageAndDescription(title);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        if (retryCount < 3) {
          console.warn(`Page not found for "${title}". Retrying (${retryCount + 1}/3).`);
          setTimeout(() => fetchPageContent(title, retryCount + 1), 1000);
        } else {
          console.error(`Failed to load page content for "${title}" after multiple attempts.`);
          alert(`Failed to load page content for "${title}" after multiple attempts. Please try a different link.`);
          resolve();
        }
      } else {
        console.error('Error fetching page content:', error);
        alert(`Failed to load page content for "${title}". Please try a different link.`);
        reject();
      }
    } finally {
      activeRequests--;
      processQueue();
    }
  };

  const fetchPageImageAndDescription = async (title) => {
    try {
      const response = await axios.get(
        `https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(title)}`
      );
      if (response.data) {
        if (response.data.thumbnail && response.data.thumbnail.source) {
          setHoveredImage(response.data.thumbnail.source);
        } else {
          setHoveredImage(null);
        }
        setHoveredDescription(response.data.description || '');
      }
    } catch (error) {
      console.error('Error fetching image and description:', error);
      setHoveredImage(null);
      setHoveredDescription('');
    }
  };

  const processQueue = () => {
    while (activeRequests < MAX_CONCURRENT_REQUESTS && requestQueue.length > 0) {
      const nextRequest = requestQueue.shift();
      nextRequest();
    }
  };

  const handleLinkClick = (linkElement) => {
    let linkHref = linkElement.getAttribute('href');
    console.log('Link href:', linkHref);

    if (!linkHref) {
      return;
    }

    if (linkHref.startsWith('./')) {
      linkHref = linkHref.replace('./', '/wiki/');
    } else if (linkHref.startsWith('../')) {
      linkHref = linkHref.replace('../', '/wiki/');
    } else if (!linkHref.startsWith('/')) {
      linkHref = '/wiki/' + linkHref;
    }

    if (!linkHref.startsWith('/wiki/')) {
      return;
    }

    if (linkHref.includes(':') || linkHref.includes('#') || linkHref.includes('?')) {
      return;
    }

    const pageTitle = decodeURIComponent(linkHref.replace('/wiki/', '')).replace(/_/g, ' ').toLowerCase();

    console.log('Navigating to page:', pageTitle);

    fetchPageContent(pageTitle)
      .then(() => {
        setClickCount((prevCount) => prevCount + 1);
        setClickedLinks((prevLinks) => [...prevLinks, pageTitle]);
      })
      .catch((error) => {
        console.error('Error loading page content:', error);
        resetRequestState();
        if (window.confirm('Failed to load page. Would you like to retry?')) {
          handleLinkClick(linkElement);  // Retry the link click
        }
      });

    if (pageTitle === endPage.replace(/_/g, ' ').toLowerCase()) {
      setGameEnded(true);
    }
  };

  const handleMouseEnter = (title) => {
    setHoveredLink(title);
    setTimeout(() => {
      setShowHoveredContent(true);
    }, 500);
    fetchPageImageAndDescription(title);
  };

  const handleMouseLeave = () => {
    setHoveredLink('');
    setHoveredImage(null);
    setHoveredDescription('');
    setShowHoveredContent(false);
  };

  useEffect(() => {
    if (currentPageContent) {
      const container = document.querySelector('.article-container');
      if (container) {
        const links = container.querySelectorAll('a[href]');
        links.forEach((link) => {
          link.addEventListener('click', (e) => {
            e.preventDefault();
            handleLinkClick(link);
          });
          link.addEventListener('mouseenter', () => {
            let linkHref = link.getAttribute('href');
            if (linkHref.startsWith('./')) {
              linkHref = linkHref.replace('./', '/wiki/');
            } else if (linkHref.startsWith('../')) {
              linkHref = linkHref.replace('../', '/wiki/');
            } else if (!linkHref.startsWith('/')) {
              linkHref = '/wiki/' + linkHref;
            }
            const pageTitle = decodeURIComponent(linkHref.replace('/wiki/', '')).replace(/_/g, ' ');
            handleMouseEnter(pageTitle);
          });
          link.addEventListener('mouseleave', handleMouseLeave);
        });
      }
    }
  }, [currentPageContent]);

  const renderPageContent = () => {
    const sanitizedContent = DOMPurify.sanitize(currentPageContent, {
      RETURN_DOM: true,
      ADD_ATTR: ['href', 'title', 'data-*'],
      ADD_TAGS: ['a', 'span', 'b', 'i'],
      KEEP_CONTENT: true,
      FORBID_TAGS: ['script', 'style']
    });

    if (!sanitizedContent.querySelector('head')) {
      const headElement = document.createElement('head');
      sanitizedContent.insertBefore(headElement, sanitizedContent.firstChild);
    }

    const head = sanitizedContent.querySelector('head');
    if (head) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://en.wikipedia.org/w/load.php?modules=site.styles&only=styles&skin=vector';
      head.appendChild(link);
    } else {
      console.error('Failed to append Wikipedia stylesheet due to missing head element.');
    }

    sanitizedContent.querySelectorAll('a[href]').forEach((link) => {
      let linkHref = link.getAttribute('href');
      if (linkHref.startsWith('./')) {
        linkHref = linkHref.replace('./', '/wiki/');
      } else if (linkHref.startsWith('../')) {
        linkHref = linkHref.replace('../', '/wiki/');
      } else if (!linkHref.startsWith('/')) {
        linkHref = '/wiki/' + linkHref;
      }
      link.setAttribute('href', linkHref);
    });

    return (
      <div
        className="article-container"
        dangerouslySetInnerHTML={{ __html: sanitizedContent.outerHTML }}
      ></div>
    );
  };

  const resetGame = () => {
    setStartPage(null);
    setEndPage(null);
    setCurrentPageContent('');
    setClickCount(0);
    setTimer(0);
    setGameEnded(false);
    setCurrentPageTitle('');
    setClickedLinks([]);
    initializeGame();
  };

  const getWikipediaLink = (articleTitle) => {
    return `https://en.wikipedia.org/wiki/${encodeURIComponent(articleTitle)}`;
  };

  if (!startPage || !endPage) {
    return <div>Loading...</div>;
  }

  if (gameEnded) {
    return (
      <div className="congrats-screen">
        <h2>Congratulations!</h2>
        <p>You reached the end page.</p>
        <p>Time Taken: {formatTime(timer)}</p>
        <p>Number of Clicks: {clickCount}</p>
        <button onClick={resetGame}>Play Again</button>
      </div>
    );
  }

  return (
    <div className="game-container">
      <div className="gui-wrapper">
        <div className="game-gui"> 
          <h1>Wiki Game</h1>
          <p>Start Page: <a href={getWikipediaLink(startPage)} target="_blank" rel="noopener noreferrer" onMouseEnter={() => handleMouseEnter(startPage)} onMouseLeave={handleMouseLeave}>{startPage}</a></p>
          <div className="click-history">
            <ul>
              {clickedLinks.map((link, index) => (
                <li key={index}>
                  <a 
                    href={getWikipediaLink(link)} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    onMouseEnter={() => handleMouseEnter(link)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <p>End Page: <a href={getWikipediaLink(endPage)} target="_blank" rel="noopener noreferrer" onMouseEnter={() => handleMouseEnter(endPage)} onMouseLeave={handleMouseLeave}>{endPage}</a></p>
          <div className="timer-clicks">
            <p>Timer: {formatTime(timer)}</p>
            <p>Clicks: {clickCount}</p>
          </div>
          {showHoveredContent && (
            <div className="hovered-content">
              <h1>{hoveredLink}</h1>
              {hoveredImage && <img src={hoveredImage} alt="Hovered article thumbnail" />}
              {hoveredDescription && <p>{hoveredDescription}</p>}
            </div>
          )}
        </div>
      </div>
      <div className="content-area">
        <h1>{currentPageTitle}</h1>
        {renderPageContent()}
              </div>
    </div>
  );
}

export default Game;
